<template>
	<div class="header_mto">
		<img class="headerimg" src="@/assets/images/img_4(3).jpg" />
		<img class="headerimg" src="@/assets/images/img_4(4).jpg" />
		<div class="headerfont">
			<p>frozen</p>
			<p>Dinners</p>
		</div>
	</div>
	<div id="bg_page">
		<div class="bg_content">
			<div class="baked_column">
				<div class="frozen_dinner">
					<div class="hdr">Frozen Casseroles</div>
					<div class="bg_item">
						<span>Almost Pizza</span>
						<span>Mac & Cheese</span>
						<span>Chicken Enchilada</span>
						<span>Cheesy Beef Enchilada</span>
						<span>Rocky Mountain Chicken</span>
						<span>Tator Tots</span>
						<span>Little Cheddar Meatball</span>
						<span>Filled Shells</span>
						<span>Lasagna</span>
						<span>Chicken Lasagna</span>
						<span>Ranch Potato</span>
						<span>Chicken Cordon Bleu</span>
						<span>Chicken Potato Bake</span>
						<span>Potato Filling</span>
						<span>Gourmet Cheese Potato</span>
						<span>Sweet Potato Casserole</span>
						<span>Dairy Casserole</span>
						<span>Spaghetti Casserole</span>
					</div>
				</div>
				<div class="frozen_dinner">
					<div class="hdr">Frozen Soups</div>
					<div class="bg_item">
						<span>Chicken Corn Noodle</span>
						<span>Creamy Chicken Rice</span>
						<span>Potato</span>
						<span>Ham & Bean</span>
						<span>Chili</span>
						<span>Broccoli Chowder</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
